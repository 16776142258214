import React from "react";
import ERP from "./Times/ERP";
import Gerencia from "./Times/Gerencia";
import Internet from "./Times/Internet";
import Operacao from "./Times/Operacao";
import Sistemas from "./Times/Sistemas";
import Suporte from "./Times/Suporte";
import Background from "./Bckground";

import FBVest from "./Times/FBVest";
import Seguranca from "./Times/Seguranca";

const Times = () => {
  return (
    <main>
      {" "}
      {/* <Background /> */}
      <section className="geral">
        <Gerencia />
        <ERP />
        <Internet />
        <Operacao />
        <Sistemas />
        <Suporte />
        {/* <Seguranca /> */}
        {/* <FBVest /> */}
      </section>
    </main>
  );
};

export default Times;
