import React, { useState, useEffect } from "react";
import servico from "../servicos/servico";
import "./Nav.css"; // Importe o arquivo CSS
import { Hamburger } from "./Hamburger";

const Nav = () => {
  const [times, setTimes] = useState([]);
  const [Equipes, setEquipes] = useState([]);
  const [gerencia, setGerencia] = useState([]);
  const [erp, setErp] = useState([]);
  const [internet, setInternet] = useState([]);
  const [operacao, setOperacao] = useState([]);
  const [sistemas, setSistemas] = useState([]);
  const [suporte, setSuporte] = useState([]);
  const [fbvest, setFBVest] = useState([]);
  const [seguranca, setSeguranca] = useState([]);
  const [hamburgerOpen, setHamburgerOpen] = useState(false);

  const obterTime = async () => {
    await servico.Gerencia().then(function (data) {
      setGerencia(data);
    });
    await servico.ERP().then(function (data) {
      setErp(data);
    });
    await servico.Internet().then(function (data) {
      setInternet(data);
    });
    await servico.Operacao().then(function (data) {
      setOperacao(data);
    });
    await servico.Sistemas().then(function (data) {
      setSistemas(data);
    });
    await servico.Suporte().then(function (data) {
      setSuporte(data);
    });
    // await servico.FBVest().then(function (data) {
    //   setFBVest(data);
    // });
    // await servico.Seguranca().then(function (data) {
    //   setSeguranca(data);
    // });
  };

  useEffect(() => {
    obterTime();
    setTimes(
      gerencia.concat(erp, internet, operacao, sistemas, suporte, seguranca)
    );
    setEquipes(times.map((o) => Object.keys(o)));
  }, [gerencia, erp, internet, operacao, sistemas, suporte, seguranca]);

  const toggleHamburger = () => {
    setHamburgerOpen(!hamburgerOpen);

    var boolean = !hamburgerOpen;

    var navigationUl = document.querySelector(".navigation ul");

    const menuButton = document.querySelector('.menu-button');
    
      if (navigationUl.classList.contains('active')) {
        // Fecha o menu
        navigationUl.classList.remove('active');
      } else {
        // Abre o menu
        navigationUl.classList.add('active');
      }
  };

  return (
    <div>
      <div className="navigation">
        <ul className="nav-menu">
          {Equipes.map((item) => (
            <li key={item} className="nav-item">
              <a href={`#${item}`} className="nav-link" onClick={toggleHamburger}>
                {item}
              </a>
            </li>
          ))}
        </ul>
        <div className="hamburgerContainer" onClick={toggleHamburger}>
          <Hamburger isOpen={hamburgerOpen} />
        </div>
      </div>

      <style jsx>{`
        .nav-item {
          position: relative;
        }
        .navigation {
          width: 100%;
          height: 50px;

          background-color: #FFF;

          display: flex;
          justify-content: center;
          align-items: center;
        }

        .navigation ul {
          display: flex;
          flex-wrap: wrap;
          float: right;
          margin: 0px;
          padding: 0px;
          overflow: hidden;
        }
        .navigation ul li {
          list-style-type: none;
          padding-right: 10px;
        }

        .hamburger {
          display: none;
          z-index: 999999;
        }

        @media (max-width: 1024px) {

          .navigation{
            background-color: transparent;
          }
          .hamburger {
            display: flex;
            align-items: center;
            padding-top: 10px;
            margin-left: 10px;
            z-index: 999999;
            position: fixed;
            right: 14px;
            top: 15px;
            background-color: ${hamburgerOpen ? "#32338e" : "#32a457"};
            border-radius: 100%;
            width: 2.8rem;
            height: 2rem;
            padding-bottom: 8px;
            padding-left: 5px;
            padding-right: 3px;
          }

          .navigation ul {
            display: ${hamburgerOpen ? "inline" : "none"};
            height: 100vh;
            width: 0;
            position: absolute;
            z-index: 999;
            top: 0;
            left: 0;
            background-color: #32a457;
            padding-top: 50px;
            overflow-x: hidden;
            transition: width 0.3s ease;
          }

          @keyframes slideIn {
            from {
              left: -100%; /* Comece fora da tela à esquerda */
              width: 0;
            }
            to {
              left: 0; /* Mova para a esquerda até cobrir a tela */
              width: 100vw;
            }
          }

          @keyframes slideOut {
            from {
              left: 0; /* Comece na tela, à esquerda */
              width: 100vw;
            }
            to {
              left: -100%; /* Mova para a esquerda e saia da tela */
              width: 0;
            }
          }

          .navigation ul.active {
            animation: slideIn 0.3s forwards;
          }

          .navigation ul:not(.active) {
            animation: slideOut 0.3s forwards;
          }
        }
      `}</style>
    </div>
  );
};

export default Nav;
